






import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import {
  CHART_CLICK_EVENT,
  TASQ_PROLONGED_ANOMALY_TYPE,
  TASQ_STATE_CHANGE_TYPE,
} from '@/lib/constants';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
import { Debounce } from 'vue-debounce-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import assetsModule from '@/store/modules/assetsModule';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import { getConfigEnv, getComponent, getView, sleep } from '@/utils/helpers';
import { DateTime } from 'luxon';
import { lightningChart, 
	OnScreenMenuButtonType, 
	OnScreenMenuButtonShape,
	Themes,
	ColorRGBA,
	ColorHEX,
	SolidFill,
	PointShape,
	SolidLine,
	AxisTickStrategies,
	LegendBoxBuilders,
	AutoCursorModes,
	LegendBox,
	MouseClickEventType,
	MouseEventHandler,
	UILUTCheckBox,
	emptyLine,
	emptyFill,
	ColorCSS,
	customTheme,
  FunnelChartWithLabelsOnSides,
	} from '@arction/lcjs'
// Extract required parts from XYData Generator.
import {
	createProgressiveTraceGenerator,
	createOHLCGenerator,
	createProgressiveRandomGenerator
} from '@arction/xydata'
import { xgcdDependencies } from 'mathjs';
@Component({
  components: {
    VueSlider,
  },
})
export default class TasqLightningChart extends Vue {

  @Prop({ type: Boolean, required: false, default: false }) isFullScreen?: boolean;



  chart:any = null
  chartId:any = null
  chartsLoading = false
	points = [
	]
  created() {
	
  }

  didSelectResetChart() {
	this.chart.getDefaultAxisX().release()
	this.chart.getDefaultAxisY().release()
	this.axisY.release()
	this.axisY2.release()
	this.axisY3.release()
  }


  get legendSignals(): any {
	var legend_signals:any = []
	for (var x = 0; x < this.currentSignals.length; x++) {
		legend_signals.push({
			name: this.currentSignals[x].name,
			selected: this.isSignalSelected(this.currentSignals[x].name),
			color: this.currentSignals[x].color,
			type: "SIGNAL"
		})
	}

	for (const [key, value] of Object.entries(this.productionDataDict)) {
		
		// @ts-ignore
		if ("water_rate" != key && "gas_rate" != key && "oil_rate" != key || (value == undefined || value.length == 0)) {
			continue
		}
		var color = ''
		if ("water_rate" == key) {
			color = "#0077ff"
		} else if ("gas_rate" == key) {
			color = "#f55d8b"
		} else if ("oil_rate" == key) {
			color = "#2de6c1"
		}
		legend_signals.push({
			name: key,
			selected: this.isSignalSelected(key),
			color: color,
			type: "PRODUCTION"
		})
	}
	return legend_signals
  }



  getSignalIndex(signal_name) {
	for (var x = 0; x < this.chartSeries.length; x++) {
		var check_signal = signal_name.replace("_", " ")
		if (this.chartSeries[x].Uc.toLowerCase() == check_signal.toLowerCase()) {
			return x
		}
	}
	return -1
  }

  async updateChartToMatchLegend() {
	
	for (var a = 0; a < this.legendSignals.length; a++) {
		if (tasqSignalsModule.selectedSignals.indexOf(this.legendSignals[a].name) > -1) {

			this.chartSeries[this.getSignalIndex(this.legendSignals[a].name)].restore()

		} else {
			this.chartSeries[this.getSignalIndex(this.legendSignals[a].name)].dispose()
		}	
	}
  }


	async initializePage(index, signal) {
		for (var x = 0; x < this.chartSeries.length; x++) {
			var check_signal = signal.replace("_", " ")
			if (this.chartSeries[x].Uc.toLowerCase() == check_signal.toLowerCase()) {
				if (this.chartSeries[x].isDisposed()) {
					this.chartSeries[x].restore()
				} else {
					this.chartSeries[x].dispose()
				}
			}
		}
	}

  get isEditing() {
	  return tasqsListModule.isEditing;
  }
  get activeTasq() {
    if (this.isEditing || tasqsListModule.checkedTasqs.length) {
		if (!tasqsListModule.activeTasq) {
			tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
		}
		return tasqsListModule.activeTasq as TasqJob;
	}
    if (this.$route.query.type == 'id' && this.$route.params.id != null) {
      assetsModule.setActiveProducingTasq('');
      return tasqsListModule.activeTasq;
    }
    if (tasqsListModule.activeTasq != null && this.$route.params.id != null && this.$route.query.type == 'producing' && tasqsListModule.activeTasq.level.toLowerCase() == 'pad') {
	  return tasqsListModule.activeTasq;
    }
    if (assetsModule.activeTasq == undefined) {
      return tasqsListModule.activeTasq;
    }
    return assetsModule.activeTasq;
  }
  get tasqListLevel() {
	  if (tasqsListModule.tasqListLevel.toLowerCase() == 'well' || (this.activeTasq && tasqsListModule.tasqListLevel.toLowerCase() !== 'wellview' && this.activeTasq.level.toLowerCase() == 'well')) {
		  return 'Well';
	  }
	  return tasqsListModule.tasqListLevel.toLowerCase();
  }
  get signalDescriptions(): any {
	  return tasqSignalsModule.signalDescriptions;
  }
  get currentSignals(): any {
	return tasqSignalsModule.currentSignals
  }


  isSignalSelected(signal_name) {
	return (tasqSignalsModule.selectedSignals.indexOf(signal_name) > -1)
	
  }


	setupForGasTargets(axisY, axisY2, legendProd, dateOrigin) {

		// Do a check if it's within the bounds

		const prodDataFrequency = 1000 * 60 * 60 * 24
		for (const [key, value] of Object.entries(tasqProductionDataChartModule.productionDataDict)) {
			if (key == "date" || value == null || value == undefined || key == "nodeid" || key.toLowerCase() == "target") {
				continue
			}



			var day_count = 0
			var check_date = new Date(this.dateOrigin.getTime())
			var new_signal_data:any = []
			while (check_date < new Date(tasqProductionDataChartModule.productionDataDict.date[0])) {


				// @ts-ignore
				new_signal_data.push({
					// @ts-ignore
					x: day_count * 24 * 60 * 60 * 1000,
					// @ts-ignore
					y: 0
				})


				check_date.setDate(check_date.getDate()+1);
				day_count += 1
			}


			var signal_data:any = []
			var prod_count = 0
			var previous_non_null_val = 0
			for (var signal_y = 0; signal_y < tasqProductionDataChartModule.productionDataDict[key].length; signal_y++) {
				 
				if (new Date(tasqProductionDataChartModule.productionDataDict["date"][signal_y]) < dateOrigin) {
					continue
				}

				// var val = parseFloat(tasqProductionDataChartModule.productionDataDict[key][signal_y])
				// if (isNaN(val)) {
				// 	val = Number.NaN
				// }







			var val = parseFloat(tasqProductionDataChartModule.productionDataDict[key][signal_y])
			if (isNaN(val)) {
				val = previous_non_null_val
			} else {
				previous_non_null_val = val
			}




				// @ts-ignore
				signal_data.push({
					// @ts-ignore
					x: (prod_count + day_count) * prodDataFrequency,
					// @ts-ignore
					y: val
				})
				prod_count += 1
			}
			if (key == "oil_rate" || key == "water_rate") {
				// Add line series to the chart
				const lineSeriesSignals = this.chart.addLineSeries({
					yAxis: this.axisY3,
				})
				
				if (key == "water_rate") {
					lineSeriesSignals.setStrokeStyle(new SolidLine({
						thickness: 2,
						fillStyle: new SolidFill({ color: ColorRGBA(0, 118, 255) })
					}))
				} else {
					lineSeriesSignals.setStrokeStyle(new SolidLine({
						thickness: 2,
						fillStyle: new SolidFill({ color: ColorRGBA(45, 230, 193) })
					}))
				}
				lineSeriesSignals.setName(key.replace("_"," "))
				// Add data points to the line series
				lineSeriesSignals.add(signal_data)
				lineSeriesSignals.dispose()

				this.chartSeries.push(lineSeriesSignals)
			}
			 else {
				// Add line series to the chart
				const lineSeriesSignals = this.chart.addLineSeries({
					yAxis: axisY
				})
				
				
				
				// Set stroke style of the line
				lineSeriesSignals.setStrokeStyle((style) => style.setThickness(2))
				lineSeriesSignals.setStrokeStyle(new SolidLine({
					thickness: 2,
					fillStyle: new SolidFill({ color: ColorRGBA(245, 93, 139) })
				}))
				lineSeriesSignals.setName(key.replace("_"," "))
				// Add data points to the line series
				lineSeriesSignals.add(signal_data)
				
				lineSeriesSignals.dispose()
				this.chartSeries.push(lineSeriesSignals)
			}


		this.axisY.setTitle("Gas & Flowrate")
		this.axisY2.setTitle("Signals")
		this.axisY3.setTitle("Oil & Water Rate")

		}
	}
	storedLinesSeries:any = []
	dateOrigin;
	legend;
	axisY;
	axisY2;
	axisY3;
	signalsAdded:any = []
    createChart() {
		const themeTextFillStyle = new SolidFill({ color: ColorCSS('#ffffff') })
		const themeDataSeriesFillStyles = [new SolidFill({ color: ColorCSS('#00FFEA') }), 
										new SolidFill({ color: ColorCSS('#F21688') }), 
										new SolidFill({ color: ColorCSS('#FFFF5D') }), 
										new SolidFill({ color: ColorCSS('#FFCD5C') }), 
										new SolidFill({ color: ColorCSS('#FFC8A5') }), 
										new SolidFill({ color: ColorCSS('#FF94B8') }), 
										new SolidFill({ color: ColorCSS('#DB94C6') }),
										new SolidFill({ color: ColorCSS('#A994C6') }),
										new SolidFill({ color: ColorCSS('#94B0C6') }),
										new SolidFill({ color: ColorCSS('#94E2C6') }), 
										new SolidFill({ color: ColorCSS('#94FFB0') }),
										new SolidFill({ color: ColorCSS('#94ffdf') }),
										new SolidFill({ color: ColorCSS('#94eaff') }),
										new SolidFill({ color: ColorCSS('#94c1ff') }),
										new SolidFill({ color: ColorCSS('#a894ff') }),
										new SolidFill({ color: ColorCSS('#ffb194') }),
										new SolidFill({ color: ColorCSS('#90e64e') }),
										new SolidFill({ color: ColorCSS('#e64e4e') }),
										new SolidFill({ color: ColorCSS('#b14ee6') }),
										new SolidFill({ color: ColorCSS('#41c459') }),
										new SolidFill({ color: ColorCSS('#41c4b0') })]
										
		const themeAxisFillStyle = new SolidFill({ color: ColorCSS('#ffffff') })
		const themeMajorTickFillStyle = new SolidFill({ color: ColorCSS('#ffffff') })
		const themeMinorTickFillStyle = new SolidFill({ color: ColorCSS('#ffffff') })
		const themeMajorGridlineFillStyle = new SolidFill({ color: ColorCSS('#00000032') })
		const themeMinorGridlineFillStyle = new SolidFill({ color: ColorCSS('#00000014') })
		const themeUiBackgroundFillStyle = new SolidFill({ color: ColorCSS('rgba(120,120,120,0.5)') }) // Legend color
		const themeUiBackgroundBorderFillStyle = new SolidFill({ color: ColorCSS('#2b2b2b') })
		const themeCursorGridlineFillStyle = new SolidFill({ color: ColorCSS('#ffffff') })
		// darkMagenta
		const myTheme = customTheme(Themes.cyberSpace, {
		lcjsBackgroundFillStyle: new SolidFill({ color: ColorCSS('#00000000') }),
		panelBackgroundFillStyle: new SolidFill({ color: ColorCSS('#00000000') }),
		seriesBackgroundFillStyle: new SolidFill({ color: ColorCSS('#00000000') }),
		chartTitleFillStyle: themeTextFillStyle,
		axisTitleFillStyle: themeTextFillStyle,
		axisStyle: new SolidLine({ thickness: 2, fillStyle: themeAxisFillStyle }),
		numericTickStrategy: Themes.lightNew.numericTickStrategy
			.setMajorTickStyle((majorTicks) =>
				majorTicks
					.setLabelFillStyle(themeTextFillStyle)
					.setTickStyle(new SolidLine({ thickness: 1, fillStyle: themeMajorTickFillStyle }))
					.setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: themeMajorGridlineFillStyle })),
			)
			.setMinorTickStyle((minorTicks) =>
				minorTicks
					// @ts-ignore
					.setLabelFillStyle(themeTextFillStyle)
					.setTickStyle(new SolidLine({ thickness: 1, fillStyle: themeMinorTickFillStyle }))
					.setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: themeMinorGridlineFillStyle })),
			),
		seriesFillStyle: (i) => themeDataSeriesFillStyles[i % themeDataSeriesFillStyles.length],
		seriesStrokeStyle: (i) => new SolidLine({ thickness: 2, fillStyle: themeDataSeriesFillStyles[i % themeDataSeriesFillStyles.length] }),
		uiBackgroundFillStyle: themeUiBackgroundFillStyle,
		uiBackgroundStrokeStyle: new SolidLine({ thickness: 1, fillStyle: themeUiBackgroundBorderFillStyle }),
		uiTextFillStyle: themeTextFillStyle,
		resultTableFillStyle: themeUiBackgroundFillStyle,
		resultTableStrokeStyle: new SolidLine({ thickness: 1, fillStyle: themeUiBackgroundBorderFillStyle }),
		resultTableTextFillStyle: themeTextFillStyle,
		customTickGridStrokeStyle: new SolidLine({ thickness: 1, fillStyle: themeCursorGridlineFillStyle }),
		uiPointableTextBoxFillStyle: themeUiBackgroundFillStyle,
		uiPointableTextBoxStrokeStyle: new SolidLine({ thickness: 1, fillStyle: themeUiBackgroundBorderFillStyle }),
		uiPointableTextBoxTextFillStyle: themeTextFillStyle,
		pointMarkerFillStyle: new SolidFill({ color: ColorCSS('#ffffff') }),
		chartXYZoomingRectangleFillStyle: new SolidFill({ color: ColorCSS('#ffffff16') }),
		chartXYZoomingRectangleStrokeStyle: new SolidLine({ thickness: 1, fillStyle: new SolidFill({ color: ColorCSS('#4f4f4f') }) }),
		chartXYFittingRectangleFillStyle: new SolidFill({ color: ColorCSS('#ffffff16') }),
		chartXYFittingRectangleStrokeStyle: new SolidLine({ thickness: 1, fillStyle: new SolidFill({ color: ColorCSS('#4f4f4f') }) }),
		})
	this.chart = lightningChart().ChartXY({container: `${this.chartId}`, theme: myTheme}).setTitle("")
	this.chart.setAnimationsEnabled(false)
	this.chart.setMouseInteractionWheelZoom(false)
	
	this.axisY = this.chart.getDefaultAxisY()
	this.axisY2 = this.chart.addAxisY({
		opposite: true
	})

	this.axisY3 = this.chart.addAxisY({
	})

	// Hide tick grid-lines from second Y axis.
	.setTickStrategy(AxisTickStrategies.Numeric, (ticks) => ticks
		.setMinorTickStyle(minor => minor
		.setGridStrokeStyle(emptyLine)
		)
		.setMajorTickStyle(major => major
		.setGridStrokeStyle(emptyLine)
		)
	)


	
	// var offset = new Date().getTimezoneOffset();
	var d = new Date(tasqsListModule.chartFromTime);
	// d.setDate(d.getDate()-tasqsListModule.signalChartTime);
	// d.setMinutes(d.getMinutes() - offset);
	this.dateOrigin = d
	this.chart.getDefaultAxisX().setTickStrategy(AxisTickStrategies.DateTime, (tickStrategy) => tickStrategy.setDateOrigin(this.dateOrigin))




  }
	chartSeries:any = []
	addSignalToChart(newSignal) {
	// @ts-ignore
	
		var resultInMinutes = 5
		var signal_data:any = []

		var previous_non_null_val = 0
		var encountered_a_non_null = false

		// var offset = new Date().getTimezoneOffset();
		for (var signal_y = 0; signal_y < newSignal.dataset.length; signal_y++) {

			
			var val = parseFloat(newSignal.dataset[signal_y])
			if (isNaN(val)) {
				if (!encountered_a_non_null) {
					continue
				}
				val = previous_non_null_val
			} else {
				encountered_a_non_null = true
				previous_non_null_val = val
			}


			// @ts-ignore
			signal_data.push({
				// @ts-ignore
				x: (signal_y * (resultInMinutes * 1000 * 60)),
				// @ts-ignore
				y: val
			})
		}
		var axis_to_use = this.axisY2
		if (newSignal.name == "Flowrate") {
			axis_to_use = this.axisY
		}
		// Add line series to the chart
		const lineSeriesSignals = this.chart.addLineSeries({
			yAxis: axis_to_use,
		})
		this.chartSeries.push(lineSeriesSignals)
		
		lineSeriesSignals.setStrokeStyle(new SolidLine({
			thickness: 1.5,
			fillStyle: new SolidFill({ color: ColorHEX(newSignal.color) })
		}))
		// Set stroke style of the line
		lineSeriesSignals.setStrokeStyle((style) => style.setThickness(1.5))
		lineSeriesSignals.setName(newSignal.name)
		// Add data points to the line series
		lineSeriesSignals.add(signal_data)
		if (!this.isSignalSelected(newSignal.name)) {
			lineSeriesSignals.dispose()
		}

	}
	setSeriesVisibility() {
		// this.chartSeries
	}

	get productionDataDict() {
		return tasqProductionDataChartModule.productionDataDict
	}

	get addEditableCurrentSignals() {
		return tasqSignalsModule.addEditableCurrentSignals
	}

    @Watch('addEditableCurrentSignals')
    updateChartSignalsVisibility(data) {
		// this.addSignalToChart(this.currentSignals[this.currentSignals.length - 1])
    }

    @Watch('currentSignals')
    updateChartSignals(data) {
		if (this.currentSignals.length - 1 < 0) {
			return
		}
		this.addSignalToChart(this.currentSignals[this.currentSignals.length - 1])
		
    }

	didAddProductionData:any = {}
	@Watch('productionDataDict')
    updateChartProdSignals(data) {
		for (const [key, value] of Object.entries(tasqProductionDataChartModule.productionDataDict)) {
			if (this.didAddProductionData[key] == undefined) {


					this.setupForGasTargets(this.axisY, this.axisY2, this.legend, this.dateOrigin)	
					this.didAddProductionData[key] = key
				break
			}
		}
    }


  beforeMount() {
    // Generate random ID to us as the containerId for the chart and the target div id
    this.chartId = Math.trunc(Math.random() * 1000000)
  }
   mounted() {
    // Chart can only be created when the component has mounted the DOM because 
    // the chart needs the element with specified containerId to exist in the DOM



	this.createChart()

	if (this.isFullScreen) {
		for (var x = 0; x < this.currentSignals.length; x++) {
			this.addSignalToChart(this.currentSignals[x])
		}
			
		for (const [key, value] of Object.entries(tasqProductionDataChartModule.productionDataDict)) {
			if (this.didAddProductionData[key] == undefined) {
					this.setupForGasTargets(this.axisY, this.axisY2, this.legend, this.dateOrigin)	
					this.didAddProductionData[key] = key
				break
			}
		}
		this.updateChartToMatchLegend()
	}
	if (!tasqsListModule.isInitialPageLoad) {
		if (Object.keys(tasqProductionDataChartModule.productionDataDict).length > 0) {
			for (const [key, value] of Object.entries(tasqProductionDataChartModule.productionDataDict)) {
				if (this.didAddProductionData[key] == undefined) {
						this.setupForGasTargets(this.axisY, this.axisY2, this.legend, this.dateOrigin)	
						this.didAddProductionData[key] = key
					break
				}
			}
		}
	}

  }

   beforeUnmount() {
    // "dispose" should be called when the component is unmounted to free all the resources used by the chart
    this.chart.dispose()
  }
}
